.text-center {
  width: 100%;
  position: relative;
  height: 100%;
}

.spinner-border {
  display: block;
  position: fixed;
  top: calc(50% - (58px / 2));  
  right: calc(50% - (58px / 2));
  color: #000000;
}

#backdrop {
  position:fixed;
  top:0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  background-color: rgb(0,0,0,0.2);
}