.btn-dropdown {
    display: none;
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    width: 100%;
    padding: 1px;
    z-index: 11;
    border: 1px solid #ddd;
    max-width: fit-content;
    min-width: 130px;
}
.btn-dropdown.active {
    display: block;
}
.btn-dropdown li {
    padding: 12px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    text-align: left;
    color: black;
}
.btn-dropdown li:hover {
    cursor: pointer;
    color: #68bd90;
}
.btn-dropdown li:last-child {
    border-bottom: none;
}
