@media only screen and (max-width: 1400px) {
    .singin__page__left {
        padding: 100px 80px;
    }
    .dashboard__body .dashboard__right {
        width: 100%;
        padding-top: 50px;
        margin: 0 auto;
        /* padding-right: 35px; */
    }
    .dashboard__body .dashboard__left {
        border-right: none;
    }
    .account__crate__title {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .account__crate__title a.btn__four {
        position: relative !important;
        right: 0px;
        top: 0px;
    }
    .account__crate__title h3 {
        padding: 15px 0px 15px;
    }
}

/* @media only screen and (max-width: 1200px) {
    .debetors__section{
        width: 100%;
    }
} */

@media only screen and (max-width: 1150px) {
    /* .invoice__section .invoice__data{
        width: 48%;
    } */
    .debetors__section {
        width: 100%;
    }
    .dashboard__body .dashboard__right {
        padding: 0px 0px 100px !important;
    }
    .Pending {
        position: relative !important;
        right: 0px;
        top: 0px;
        font-size: 12px;
        padding: 5px 8px !important;
        min-width: max-content;
    }
    .Approved {
        position: relative !important;
        right: 0px;
        top: 0px;
        font-size: 12px;
        padding: 5px 8px !important;
        min-width: max-content;
    }
    .Disapproved {
        position: relative !important;
        right: 0px;
        top: 0px;
        font-size: 12px;
        padding: 5px 8px !important;
        min-width: max-content;
    }
}
@media only screen and (min-width: 991.98px)
{
    .hidden-sidebar.open {  -webkit-transform: translate3d(0px, 0, 0);
        transform: translate3d(0px, 0, 0);}
}

@media only screen and (max-width: 991.98px) {
    .dashboard__body .dashboard__right {
        padding: 0px 0px 100px !important;
    }
    .debetors__section {
        width: 100%;
    }
    .dashboard__menu li {
        padding-bottom: 50px;
        position: relative;
    }
    .singin__page {
        flex-wrap: wrap;
    }
    .singin__page__left {
        width: 100%;
        padding: 0px 20px 0px;
        order: 2;
        min-height: min-content;
    }
    .singin__page__right {
       display: none;
    }
    .singin__page__right .logo {
        position: relative;
        text-align: center;
    }
    .singin__page__left .from__title {
        text-align: center;
    }
    .logo.d-none {
        display: block !important;
        text-align: center;
        padding-bottom: 100px;
    }
    .reset__password__page,
    .forget__password__page,
    .password__success__page {
        max-width: 100%;
        box-shadow: none;
        padding: 30px 20px 100px;
        margin: 0;
    }
    .dashboard__menu {
        display: none;
    }
    .dashboard__header {
        display: none;
    }
    .mobail__menu__box .balance p {
        display: flex;
        color: #182123;
    }
    .mobail__menu__box .balance p i {
        padding-right: 10px;
        font-size: 18px;
    }
    .mobail__menu__box .balance p span {
        color: #68bd90;
        padding-left: 10px;
    }
    .hidden-sidebar.open .dashboard__menu {
        display: block !important;
        text-align: center;
    }
    .dashboard__right.account form {
        width: 100%;
    }
    .tableof__invoice.debetors td {
        min-width: 212px;
    }
    .invoices__table p.d-none {
        display: block !important;
        padding-top: 14px;
        color: #ff0000;
    }
}
@media only screen and (max-width: 780px) {
    .invoice__section .invoice__data {
        width: 100%;
    }
    .invoice__form .tabs ul.tabs-nav {
        flex-wrap: wrap;
        justify-content: center;
        position: relative;
    }
    .invoice__popup .invoice__form {
        margin-left: 20px !important;
        margin-right: 20px !important;
    }
    .dashboard__body .dashboard__right {
        padding-top: 0px;
    }
    .invoice__data .invoice.link a {
        position: relative;
    }
    .contact__us {
        display: flex;
        flex-wrap: wrap;
    }
    .contact__us__right {
        text-align: center;
        display: block;
        order: 1;
        width: 100%;
        padding-top: 40px;
    }
    .contact__us__left {
        order: 2;
    }
    .dashboard__body .dashboard__right {
        padding: 0px 12px 100px !important;
    }
    .dashboard__body .dashboard__left {
        padding: 0px;
    }
}

@media only screen and (max-width: 600px) {
    .Pending {
        position: relative !important;
        right: 0px;
        top: 0px;
        font-size: 12px;
        padding: 5px 8px !important;
        width: max-content;
    }
    .Approved {
        position: relative !important;
        right: 0px;
        top: 0px;
        font-size: 12px;
        padding: 5px 8px !important;
        width: max-content;
    }
    .Disapproved {
        position: relative !important;
        right: 0px;
        top: 0px;
        font-size: 12px;
        padding: 5px 8px !important;
        width: max-content;
    }
    .invoices__table__header {
        display: block;
    }
    .invoices__table__header .invoices__table__right {
        padding-top: 10px;
    }
    .invoices__table {
        padding: 30px 30px 50px;
    }
    .debtor__popup .debtor__form {
        margin-left: 20px !important;
        margin-right: 20px !important;
    }
    .argeement__uplode__popup .argeement__uplode__form {
        margin-left: 20px !important;
        margin-right: 20px !important;
    }
    .debetors__section .invoices__table {
        padding: 20px;
    }

    .invoices__table__right .serch__option input {
        width: 100%;
    }
    .invoices__table__right .serch__option {
        width: 100%;
    }

    .singin__page__right {
        width: 100%;
        background: #fff;
        padding: 0px 20px 0px;
    }

    .singin__page__main {
        padding: 12px 0px;
    }
}

@media only screen and (max-width: 500px) {
    .account__crate__title a.btn__four {
        padding: 5px 6px;
        font-size: 10px;
    }
    .accordion-box-style1 .accordion .accord-content {
        padding: 20px;
    }
    .accordion-box-style1 .accordion .accord-btn {
        padding: 20px 20px;
    }
    .accordion-box-style1 .collapse-icon {
        right: 20px;
    }
    .invoices__table__right .serch__option input {
        width: 100%;
        padding-left: 40px;
    }
    .invoices__table__right .serch__option i {
        left: 11px;
    }
    .invoices__table__right .serch__option {
        width: 100%;
    }
}
@media only screen and (max-width: 400px) {
    .listof__invoice .check__box {
        padding-bottom: 10px;
    }
    .debetors__section .invoices__table {
        padding: 30px;
    }
    .account__crate__title {
        display: block;
        padding-bottom: 20px;
    }
}
