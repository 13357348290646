.underline {
    text-decoration: underline !important;
}

.dashboard-card-icon {
    background: rgba(104, 189, 144, 0.4);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    text-align: center;
    line-height: 100px;
    vertical-align: middle;
    padding: 20px;
}
.invoice__data {
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.09);
    border-radius: 5px;
}
.dashbord-card-disabled{
    cursor: default !important;
    color: #6b7b99;
}
.dashbord-card-disabled:hover{
    color: #6b7b99;
}
@media (min-width: 992px) and (max-width: 1136px) {
    .invoice__data .invoice__text h3 {
        font-size: 24px !important;
    }
}
