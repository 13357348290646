.nav-link-disabled {
    pointer-events: none;
    opacity: 0.6;
}

.bottom-sidebar-ul{position: fixed;bottom: 1vw;width: 300px;}

.bottom-sidebar-a {
  cursor: pointer !important;
  font-family: 'Mulish', sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #726e6e !important;
  letter-spacing: 1px !important;
  transition: all 500ms ease !important;
  position: relative !important;
}

.bottom-sidebar-ul>li>a:hover{
  color: #68bd90 !important;
}

@media (max-height: 700px) {
    .bottom-sidebar-ul{position: relative;bottom: 1vw;}
  }

  @media only screen and (max-width: 991.98px) {
    .bottom-sidebar-ul{width: 100%;}
  }