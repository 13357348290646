@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap');

body {
    font-family: 'Mulish', sans-serif;
    font-style: normal;
    font-weight: 450;
    font-size: 16px;
    line-height: 18px;
    color: #000000;
}
