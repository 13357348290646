.btn-users {
    font-size: 16px;
    color: #FFFFFF;
    background: #172528;
    border-radius: 20px;
    padding: 5px 23px;
}

.pending-status {
    color: #FF0000;
}

.approved-status {
    color: #4BAE8A;
}

.view__debtoe__popup__btn {
    padding-top: 50px;
    width: 122px;
    margin: 0 0 0 auto;
    padding-bottom: 30px;
}

.btn__one.three {
    background: #FF0000 !important;
}

.disabled {
    background: rgb(226 226 226 / 90%) !important;
}