.page-item .page-link {
    background-color: #caf8d4 !important;
    border-color: #adb3ae !important;
    color: black !important;
}
.page-item.active .page-link {
    background-color: black !important;
    color: white !important;
}

.page-item.disabled .page-link {
    opacity: 0.7;
}
