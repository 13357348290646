.pull-right {
    float: right;
}

.medium-font {
    font-size: 14px;
}

span:hover {
    cursor: pointer;
    color: #68bd90;
}

.worning__account {
    padding-top: 35px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #FF0000;
}

.account__btn-request {
    width: 225px;
    margin: 0 auto 0 0;
    padding-top: 30px;
}

.country .css-k3ys7k-control {
    background: rgb(226 226 226 / 90%) !important;
}

.userType .css-k3ys7k-control {
    background: rgb(226 226 226 / 90%) !important;
}

.disabled {
    background: rgb(226 226 226 / 90%) !important;
}