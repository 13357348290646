.fq-button {
    position: relative;
    overflow: hidden;
    display: inline-block;
    border-radius: 20px;
    color: #ffffff;
    font-size: 16px;
    line-height: 23px;
    font-weight: 500;
    padding: 12px 0px;
    /* height: 50px; */
    text-align: center;
    -webkit-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
    text-decoration: none;
    font-family: 'Mulish', sans-serif;
    z-index: 1;
    border: none;
}
.fq-button:hover {
    color: white;
}
.fq-button-md {
    /* width: 125px; */
    padding: 14px 32px;
    width: max-content;
    min-width: 100px;
}
.fq-button-lg {
    width: 100%;
}
.fq-button::before {
    position: absolute;
    top: 0px;
    left: 0;
    bottom: 0px;
    width: 50%;
    content: '';
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    -webkit-transition-duration: 800ms;
    transition-duration: 800ms;
    z-index: 2;
}
.fq-button::after {
    position: absolute;
    top: 0px;
    right: 0;
    bottom: 0px;
    width: 50%;
    content: '';
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-transition-duration: 800ms;
    transition-duration: 800ms;
    z-index: 2;
}
.fq-button:hover::before,
.fq-button:hover::after {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
}
.fq-button span {
    position: relative;
    z-index: 11;
}

.fq-button-primary {
    background-color: #68bd90;
}
.fq-button-primary:hover:before {
    background-color: #172528;
}
.fq-button-primary:hover::after {
    background-color: #172528;
}

.fq-button-secondary {
    background-color: #172528;
}
.fq-button-secondary:hover::before,
.fq-button-danger:hover::before {
    background-color: #68bd90;
}
.fq-button-secondary:hover:after,
.fq-button-danger:hover::after {
    background-color: #68bd90;
}
.fq-button-danger {
    background-color: #ff0000;
}

.fq-button-sm {
    width: max-content;
    border: none;
    border-radius: 20px;
    padding: 6px 24px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    transition: all 500ms ease;
}

.fq-button-sm-primary {
    background-color: #caf8d4;
    color: #000000;
}

.fq-button-sm-secondary {
    background-color: #172528;
    color: #ffffff;
}

.fq-button-sm-danger {
    background-color: #ff0000;
    color: #ffffff;
}

.fq-button:not(.fq-button-sm) > span:hover {
    color: white;
}

.fq-button-disabled {
    pointer-events: none;
    background-color: #abb7b0 !important;
    color: #000 !important;
}

.fq-button-disabled > span {
    pointer-events: none;
}
