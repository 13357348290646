.fq-tooltip-icon {
    bottom: 10px;
    left: 100%;
    margin-left: 4px;
}

.fq-tooltip-content {
    visibility: hidden;
    width: 327px;
    background: #ffffff;
    border-radius: 5px;
    color: #151718;
    justify-content: center;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
    font-size: 12px;
    align-items: center;
    text-align: justify;
    padding: 15px;
    position: absolute;
    z-index: 500;
    left: 10%;
}

.fq-tooltip:hover .fq-tooltip-content {
    visibility: visible;
}

.fq-tooltip {
    cursor: pointer;
}
