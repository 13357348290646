@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap');

body {
    font-family: 'Mulish', sans-serif;
    font-style: normal;
    font-weight: 450;
    font-size: 16px;
    line-height: 18px;
    color: #000000;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    font-family: 'Mulish', sans-serif;
    font-style: normal;
    margin-bottom: 0px;
}

h2 {
    font-weight: 500;
    font-size: 36px;
    line-height: 46px;
    color: #000000;
}

h3 {
    font-weight: 700;
}

p {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #6b7b99;
}

/* label {
    font-weight: 450;
    font-size: 16px;
    line-height: 20px;
    color: #151718;
    padding-bottom: 10px;
} */
label:not(.file-upload-label):not(.form-check-label) {
    font-weight: 450;
    font-size: 16px;
    line-height: 20px;
    color: #151718;
    padding-bottom: 10px;
}

li {
    list-style: none;
}

/* input {
    width: 100%;
    padding: 15px;
    border: 1px solid #d3d4d8;
    border-radius: 5px;
    height: 45px;
    font-family: 'Mulish', sans-serif;
    color: #676f7a;
    font-size: 14px;
} */
input:not([type='checkbox']) {
    width: 100%;
    padding: 15px;
    border: 1px solid #d3d4d8;
    border-radius: 5px;
    height: 45px;
    font-family: 'Mulish', sans-serif;
    font-size: 14px;
    color: #676f7a !important;
}

input[type='password'] {
    background-color: #fff;
}

.form-group {
    margin-bottom: 20px;
}

.boxed_wrapper {
    position: relative;
    margin: 0 auto;
    overflow: hidden !important;
    width: 100%;
    min-width: 300px;
}

a {
    text-decoration: none;
    font-family: 'Mulish', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #68bd90;
    transition: all 500ms ease;
}

a:hover {
    color: #172528;
    cursor: pointer;
}

/* .auto__container {
    max-width: 1440px;
    margin: 0 auto;
} */
.auto__container {
    width: 100%;
    /* padding: 0px 25px; */
    margin: 0 auto;
}

ul {
    margin: 0;
    padding: 0;
}

/* botton */

.btn__one {
    position: relative;
    overflow: hidden;
    display: inline-block;
    border-radius: 20px;
    background-color: #68bd90;
    color: #ffffff;
    font-size: 18px;
    line-height: 23px;
    font-weight: 500;
    padding: 12px 0px;
    width: 100%;
    text-align: center;
    text-transform: capitalize;
    -webkit-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
    text-decoration: none;
    font-family: 'Mulish', sans-serif;
    z-index: 1;
}

.btn__one:before {
    position: absolute;
    top: 0px;
    left: 0;
    bottom: 0px;
    width: 50%;
    content: '';
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    -webkit-transition-duration: 800ms;
    transition-duration: 800ms;
    background-color: #172528;
    z-index: 2;
}

.btn__one:hover:before {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
}

.btn__one:after {
    position: absolute;
    top: 0px;
    right: 0;
    bottom: 0px;
    width: 50%;
    content: '';
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-transition-duration: 800ms;
    transition-duration: 800ms;
    background-color: #172528;
    z-index: 2;
}

.btn__one:hover:after {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
}

.btn__one .text {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
}

.btn__one:hover,
.btn__one:focus {
    color: #ffffff;
}

button.btn__one {
    border: none;
}

.btn__one.two {
    background-color: #172528;
}

.btn__one.two:before {
    background-color: #68bd90;
}

.btn__one.two:after {
    background-color: #68bd90;
}

/* check__box */

.check__box input {
    display: none;
}

.check__box label {
    position: relative;
    font-size: 16px;
    line-height: 20px;
    color: #141417;
    padding-left: 26px;
    font-weight: 400;
    padding-bottom: 0px;
    display: inline-block;
    cursor: pointer;
    margin-bottom: 0px;
    transition: all 500ms ease;
}

.check__box input:checked+label {
    color: #1e2c36;
}

.check__box label:before {
    position: absolute;
    content: '';
    left: 0px;
    top: 0;
    width: 17px;
    height: 17px;
    border: 1px solid #68bd90;
    border-radius: 5px;
}

.check__box label:after {
    position: absolute;
    content: '\f00c';
    font-family: 'Font Awesome 5 Pro';
    font-size: 9px;
    left: 0px;
    top: 0px;
    width: 17px;
    height: 17px;
    border-radius: 5px;
    line-height: 17px;
    color: #fff;
    opacity: 0;
    font-weight: 400;
    text-align: center;
}

.check__box input:checked+label:after {
    opacity: 1;
}

.check__box input:checked+label:after {
    background: #68bd90;
}

input:focus-visible {
    outline: none;
}

input:focus {
    border: 1px solid #172528;
}

/* check__box */

/*End Golobal Css */

/* singin__page */

.singin__page {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    height: 100vh;
    overflow: auto;
}

.singin__page__left {
    width: 50%;
    background: #fff;
    padding: 55px 180px;
}

.singin__page__main {
    padding: 80px 0px;
}

.singin__page__right {
    width: 50%;
    position: relative;
}

.singin__page__right .logo {
    height: 100vh;
}

.singin__page__right .logo img {
    width: calc(100%);
    height: 100vh;
}

.singin__page__left .from__title p {
    margin: 10px 0px 25px;
}

.singin__page .password___forget {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
}

.singin__page .form__bottom {
    text-align: center;
    padding-top: 10px;
}

/* forget__password__page */

.forget__password__page {
    position: relative;
    max-width: 486px;
    width: 100%;
    margin: 0 auto;
    background: #ffffff;
    box-shadow: 0px 4px 20px rgb(0 0 0 / 5%);
    padding: 60px 50px 70px;
    margin-top: 250px;
    margin-bottom: 250px;
}

.forget__password__page p {
    padding: 20px 0px 35px;
}

/* reset__password__page */

.reset__password__page {
    position: relative;
    max-width: 486px;
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    margin: 0 auto;
    padding: 100px 60px;
    margin-top: 194px;
    margin-bottom: 194px;
}

.reset__password__page h2 {
    padding-bottom: 50px;
}

.reset__password__page .btn__one {
    margin-top: 15px;
}

/* password__success__page */

.password__success__page {
    max-width: 486px;
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    margin: 0 auto;
    padding: 60px 60px 100px;
    margin-top: 50px;
}

.password__success__page .image__two {
    padding: 20px 0px 50px;
}

.password__success__page p {
    padding: 20px 0px 40px;
}

/* dashboard */

.dashboard__header {
    padding: 23px 60px 38px;
    display: flex;
    justify-content: space-between;
}

.dashboard__header .header__left {
    display: flex;
    align-items: center;
    gap: 98px;
    padding-right: 30px;
}

.dashboard__header .header__right {
    display: flex;
    align-items: center;
    gap: 30px;
}

.dashboard__header .account__info img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
}

.dashboard__header .header__left p {
    display: flex;
    color: #182123;
}

.tooltips {
    position: relative;
}

.tooltips .tooltiptext {
    visibility: hidden;
    width: 327px;
    background: #ffffff;
    border-radius: 5px;
    color: #151718;
    justify-content: center;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
    font-size: 12px;
    align-items: center;
    text-align: justify;
    padding: 15px;
    position: absolute;
    z-index: 1;
    left: 100%;
}

.tooltips:hover .tooltiptext {
    visibility: visible;
}

.balance.tooltips {
    cursor: pointer;
}

.balance.tooltips img.tooltip-img {
    position: absolute;
    right: -18px;
    width: 13px;
    top: 0;
}

.dashboard__header .header__left p i {
    margin-right: 10px;
    font-size: 18px;
}

.dashboard__header .header__left p span {
    margin-left: 5px;
    color: #68bd90;
}

.dashboard__header .header__right .notifications i {
    font-size: 20px;
}

.dashboard__header .header__right .notifications a {
    display: flex;
    align-items: center;
    position: relative;
}

.dashboard__header .header__right .notifications a:before {
    content: '';
    width: 9px;
    height: 9px;
    border-radius: 50%;
    position: absolute;
    background: #ff0000;
    right: 0;
    top: 0;
}

.dashboard__header .header__right .flage {
    display: flex;
    position: relative;
}

.dashboard__header .header__right a {
    color: #151718;
}

.account__info .account__data {
    display: flex;
    align-items: center;
    gap: 10px;
}

.account__info .account__data i {
    font-size: 16px;
}

.dashboard__body {
    display: flex;
    flex-wrap: wrap;
}

.dashboard__body .dashboard__left {
    position: relative;
    border-top: 1px solid #eef0f4;
    border-right: 1px solid #eef0f4;
    padding: 20px 0px 0px 20px;
}

.dashboard__menu li {
    padding-bottom: 50px;
    position: relative;
    min-width: 300px;
}

.dashboard__menu li:last-child {
    padding-bottom: 0px;
}

.dashboard__menu li a {
    font-family: 'Mulish', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #6b7b99;
    letter-spacing: 1px;
    transition: all 500ms ease;
    position: relative;
    display: block;
}

.dashboard__menu li a.active,
.dashboard__menu li a:hover {
    color: #68bd90;
}

.dashboard__menu li a span {
    margin-right: 10px;
}

.dashboard__menu li .active:before {
    content: '';
    position: absolute;
    width: 6px;
    height: 40px;
    background: #68bd90;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    left: -30px;
    top: 50%;
    transition: all 500ms ease;
    transform: translate(0%, -50%);
}

.invoice__section {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 35px;
}

.invoice__section .invoice__data {
    /* width: 475px;
    height: 351px; */
    background: #ffffff;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    padding: 20px 30px 30px 40px;
}

.dashboard__body .dashboard__right {
    padding-left: 35px;
    padding-bottom: 100px;
}

.invoice__section .invoice__data .icon__outer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.invoice__data .icon__outer .icon__left {
    width: 65px;
    height: 65px;
    background: rgba(104, 189, 144, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.invoice__data .icon__outer .icon__left span {
    font-size: 31px;
    color: #68bd90;
}

.invoice__data .icon__outer .icon__right span {
    font-size: 20px;
}

.invoice__data .invoice__text {
    padding: 20px 0px 30px;
}

.invoice__data .invoice__text h3 {
    font-weight: 700;
    font-size: 30px;
    line-height: 38px;
    color: #151718;
}

.invoice__data .invoice__text h1 {
    font-weight: 700;
    font-size: 64px;
    padding-top: 10px;
    color: #68bd90;
}

.invoice__data .invoice.link a {
    font-weight: 450;
    font-size: 20px;
    line-height: 25px;
    color: #151718;
    text-decoration: underline;
}

.invoices__table {
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    margin-top: 30px;
    padding: 30px 60px 50px;
    overflow-x: auto;
}

.invoices__table__header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 30px;
}

.invoices__table__header .invoices__table__right {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 15px;
}

.invoices__table__left h4 {
    font-size: 24px;
    font-weight: 700;
}

.invoices__table__right .serch__option {
    position: relative;
}

.invoices__table__right .serch__option i {
    position: absolute;
    left: 24px;
    top: 50%;
    transform: translate(0%, -50%);
    color: #6b7b99;
}

.invoices__table__right .short__option .nice-select.wide {
    border: none;
    padding-right: 0px;
}

.invoices__table__right .short__option .nice-select.wide .current {
    font-size: 18px;
    display: flex;
    align-items: center;
    gap: 10px;
    width: 77px;
}

.invoices__table__right .short__option .nice-select:after {
    display: none;
}

.invoices__table__right .serch__option input {
    width: 198px;
    padding-left: 57px;
    color: #6b7b99;
}

.invoices__table__right .short__option .nice-select .list {
    width: 80px;
}

.tableof__invoice thead th {
    border: none;
    padding-left: 100px;
    padding-right: 0;
    text-align: center;
    padding-bottom: 20px;
}

.tableof__invoice thead th:first-child {
    padding-left: 0px;
}

.tableof__invoice tbody tr {
    text-align: center;
}

.tableof__invoice tbody tr td {
    padding-left: 100px;
    border-bottom: none;
    font-family: 'Mulish', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    padding: 14px 0px 14px 100px;
}

.tableof__invoice tbody tr td:first-child {
    padding-left: 0px;
}

.tableof__invoice tbody tr:nth-child(even) {
    background: #fff;
}

.tableof__invoice tbody tr:nth-child(odd) {
    background: rgba(75, 174, 138, 0.1);
}

.tableof__invoice tbody tr:nth-child(even) td {
    color: #6b7b99;
}

.tableof__invoice tbody tr:nth-child(odd) td {
    color: #68bd90;
}

.notification__view {
    background: #ffffff;
    box-shadow: 0px 5px 20px rgb(0 0 0 / 5%);
    padding: 15px 0px 0px;
    position: absolute;
    right: 0;
    width: 304px;
    z-index: 11;
}

.notifications {
    position: relative;
}

.notification__header {
    display: flex;
    justify-content: space-between;
    padding: 0px 18px 22px;
}

.notification__header__left span {
    font-size: 14px;
}

.notification__header__right a {
    font-size: 14px;
    text-decoration: underline;
}

.notification__list li:first-child:before {
    content: '';
    position: absolute;
    display: inline-block;
    background: rgba(0, 0, 0, 0.1);
    left: 0;
    top: 0;
    height: 1px;
    width: 114%;
}

.notification__list li:after {
    content: '';
    position: absolute;
    display: inline-block;
    background: rgba(0, 0, 0, 0.1);
    left: 0;
    bottom: 0;
    height: 1px;
    width: 100%;
}

.notification__list li .notifaction__all__data {
    width: 100%;
    display: flex;
    gap: 12px;
    padding: 10px 18px 0px;
    position: relative;
}

.notification__list li:hover {
    background: #e7eaf1;
}

.notification__list li {
    position: relative;
}

.notification__img img {
    width: 31px;
    height: 31px;
    border-radius: 50%;
}

.notification__data h5 {
    font-size: 14px;
    padding-bottom: 5px;
}

.notification__data p {
    font-size: 14px;
}

.notification__data p.time {
    padding: 5px 0px 15px;
    color: #9fadbf;
    font-weight: 500;
}

.dashboard__header .header__right .notification__header a:before {
    display: none;
}

.side-notifications {
    display: none;
}

.side-notifications.hidden__notifications {
    display: block;
}

.account__list {
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    width: 222px;
    padding: 20px;
    z-index: 11;
    position: relative;
}

.account__list li a {
    color: #6b7b99 !important;
    font-size: 16px;
}

.account__list li {
    padding: 15px 0px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.account__list li:first-child {
    padding-top: 0px;
}

.account__list li:last-child {
    padding-bottom: 0px;
    border: none;
}

.hide__account {
    display: none;
}

.hide__account.hidden__account {
    position: absolute;
    display: block;
    right: 0;
}

.account__info {
    position: relative;
}

.hide__language {
    display: none;
}

.hide__language.hidden__language {
    display: block;
    position: absolute;
    right: 0;
    top: 21px;
}

.hide__language.hidden__language .language__list li {
    display: flex;
    gap: 12px;
    padding-bottom: 11px;
}

.language__list {
    background: #ffffff;
    box-shadow: 0px 4px 20px rgb(0 0 0 / 5%);
    border-radius: 5px;
    width: 150px;
    padding: 20px;
    z-index: 1111;
    position: relative;
}

.hide__language.hidden__language .language__list li:last-child {
    padding-bottom: 0px;
}

.notification__list li:nth-child(2):before {
    content: '';
    width: 9px;
    height: 9px;
    border-radius: 50%;
    position: absolute;
    background: #ff0000;
    right: 12px;
    top: 12px;
}

.invoice__data .invoice.link a i {
    padding-left: 30px;
}

/* .tableof__invoice.invoices thead th:last-child {
    padding-right: 45px;
} */

/** invoice pop-up style **/

.invoice__popup {
    position: fixed;
    left: 0;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 1000;
    visibility: hidden;
    opacity: 0;
    overflow: auto;
    background: rgba(226, 226, 228, 0.8);
    -webkit-transform: translateY(101%);
    -ms-transform: translateY(101%);
    transform: translateY(101%);
    transition: all 700ms ease;
    -moz-transition: all 700ms ease;
    -webkit-transition: all 700ms ease;
    -ms-transition: all 700ms ease;
    -o-transition: all 700ms ease;
}

.invoice__popup.popup__visible {
    -webkit-transform: translateY(0%);
    -ms-transform: translateY(0%);
    transform: translateY(0%);
    visibility: visible;
    opacity: 1;
}

.invoice__popup .overlay__layer {
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    display: block;
}

.invoice__popup .close__invoice i {
    position: absolute;
    right: 25px;
    top: 25px;
    font-size: 22px;
    color: #000000;
    cursor: pointer;
    z-index: 5;
    transition: all 500ms ease;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transform: rotate(-45deg);
}

/* .invoice__popup .invoice__form {
    position: relative;
    padding: 30px 40px 100px;
    max-width: 770px;
    margin: 0 auto;
    margin-top: 150px;
    margin-bottom: 100px;
    opacity: 0;
    -webkit-transform: translateY(50px);
    -ms-transform: translateY(50px);
    transform: translateY(50px);
    z-index: 10;
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
}
.invoice__popup.popup__visible .invoice__form {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px);
    transition: all 500ms ease 500ms;
    -moz-transition: all 900ms ease 500ms;
    -webkit-transition: all 900ms ease 500ms;
    -ms-transition: all 900ms ease 500ms;
    -o-transition: all 900ms ease 500ms;
} */
.invoice__crate__title h3 {
    font-size: 24px;
    line-height: 30px;
    padding-bottom: 30px;
    color: #151718;
}

.invoice__form .nice-select.wide {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    background: #fbfbfd;
    border: 1px solid #c1cbd1;
    border-radius: 3px;
    margin-bottom: 20px;
}

.invoice__form .nice-select.wide span.current {
    font-size: 14px;
    padding-left: 14px;
    color: #151718;
}

.invoice__form .nice-select:after {
    margin-top: -10px;
}

.invoice__form .tabs li .package {
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding: 30px 20px;
    cursor: pointer;
}

.invoice__form .tabs ul.tabs-nav {
    display: flex;
    gap: 28px;
}

.invoice__form .tabs li {
    text-align: center;
}

.invoice__form .tabs li .package h4 {
    font-size: 18px;
    line-height: 25px;
    color: #000000;
}

.invoice__form .tabs li a {
    text-decoration: none !important;
}

.invoice__form .tabs li .package h3 {
    font-size: 22px;
    line-height: 31px;
    padding: 10px 0px 15px;
    color: #000000;
}

.invoice__form .tabs li .package p {
    font-size: 14px;
    line-height: 18px;
}

.invoice__form .tabs li .package__two {
    width: 144px;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    padding: 18px;
    margin: 33px auto 0px;
    cursor: not-allowed;
}

.invoice__form .tabs li .package__two h3 {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #afafaf;
}

.invoice__form .tabs li.tab-active .package {
    background: #f5fef7;
    border: 2px solid #caf8d4;
    border-radius: 10px;
}

.invoice__form .tabs li .package__two {
    background: rgba(254, 254, 254, 0.69);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
}

.package__two-tab-active {
    border: 2px solid #caf8d4;
    border-radius: 10px;
}

.invoice__form .tabs {
    margin-bottom: 30px;
}

.invoice__form input {
    background: #fbfbfd;
    border: 1px solid #c1cbd1;
    border-radius: 3px;
}

.invoice__popup .uplode__file {
    display: flex;
    align-items: center;
    gap: 13px;
    padding: 10px 0px 10px;
}

/* .uplode__file__left {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border: 1px solid #c1cbd1;
    border-radius: 10px;
} */
.uplode__file__left {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 34px;
    border: 1px solid #c1cbd1;
    border-radius: 10px;
}

.uplode__file__left span {
    font-size: 25px;
    color: #ff0000;
}

/* .uplode__file__right h3 {
    font-size: 18px;
    font-weight: 700;
} */
.uplode__file__right h3 {
    font-size: 16px;
}

.invoice__check__list h3 {
    font-size: 18px;
    padding-bottom: 15px;
}

.listof__invoice .check__box label {
    font-size: 16px;
    font-weight: 300;
}

.invoice__popup .invoice__btn {
    padding-top: 60px;
    width: 188px;
}

.invoice__popup .invoice__btn button.btn__one {
    color: #000;
    transition: all 500ms ease;
}

.invoice__popup .invoice__btn button.btn__one span.icon-cloud-computing {
    padding-right: 15px;
    font-size: 28px;
    color: #ff0000;
}

.invoice__popup .invoice__btn button.btn__one:hover {
    color: #fff;
}

/** invoice pop-up style **/

/** Add Debtor pop-up style **/

.debtor__popup {
    position: fixed;
    left: 0;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 99999;
    visibility: hidden;
    opacity: 0;
    overflow: auto;
    background: rgba(226, 226, 228, 0.8);
    -webkit-transform: translateY(101%);
    -ms-transform: translateY(101%);
    transform: translateY(101%);
    transition: all 700ms ease;
    -moz-transition: all 700ms ease;
    -webkit-transition: all 700ms ease;
    -ms-transition: all 700ms ease;
    -o-transition: all 700ms ease;
}

.debtor__popup.popup__visible {
    -webkit-transform: translateY(0%);
    -ms-transform: translateY(0%);
    transform: translateY(0%);
    visibility: visible;
    opacity: 1;
}

.debtor__popup .overlay__layer {
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    display: block;
}

.debtor__popup .close__debtor i {
    position: absolute;
    right: 25px;
    top: 25px;
    font-size: 22px;
    color: #000000;
    cursor: pointer;
    z-index: 5;
    transition: all 500ms ease;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transform: rotate(-45deg);
}

.debtor__popup .debtor__form {
    position: relative;
    padding: 30px 40px 30px;
    max-width: 445px;
    margin: 0 auto;
    margin-top: 150px;
    margin-bottom: 100px;
    opacity: 0;
    -webkit-transform: translateY(50px);
    -ms-transform: translateY(50px);
    transform: translateY(50px);
    z-index: 10;
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
}

.debtor__popup.popup__visible .debtor__form {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px);
    transition: all 500ms ease 500ms;
    -moz-transition: all 900ms ease 500ms;
    -webkit-transition: all 900ms ease 500ms;
    -ms-transition: all 900ms ease 500ms;
    -o-transition: all 900ms ease 500ms;
}

.debtor__crate__title h3 {
    font-size: 24px;
    line-height: 30px;
    padding-bottom: 30px;
}

.debtor__form .nice-select.wide {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    background: #fbfbfd;
    border: 1px solid #c1cbd1;
    border-radius: 3px;
    margin-bottom: 20px;
}

.debtor__form .nice-select.wide span.current {
    font-size: 14px;
    padding-left: 14px;
}

.debtor__form .nice-select:after {
    margin-top: -10px;
}

.debtor__popup .debtor__btn {
    display: flex;
    gap: 20px;
    justify-content: end;
}

.debtor__popup .debtor__btn button.btn__one {
    width: 120px;
}

.debtor__popup .debtor__btn button.btn__one.three {
    background-color: #ff0000;
}

.debtor__popup .uplode__file {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 40px;
}

.debtor__popup .uplode__file .uplode__file__left {
    width: 34px;
    height: 34px;
}

.debtor__popup .uplode__file .uplode__file__left span {
    font-size: 20px;
}

.debtor__popup .uplode__file__right h3 {
    font-size: 16px;
}

/* end dashboard */

/*start account page */

.dashboard__body .dashboard__left {
    border-top: none;
}

/* .dashboard__right.account form {
    width: 800px;
} */
/* .account-form {
    width: 800px;
} */
.dashboard__body.account {
    border-top: 1px solid #eef0f4;
    position: relative;
}

figure {
    margin-bottom: 0;
}

.account__crate__title h3 {
    padding: 35px 0px 15px;
}

.account__crate__title a.btn__four {
    position: absolute;
    right: 50px;
    top: 30px;
    border: 1px solid #ff0000;
    border-radius: 20px;
    padding: 15px 30px;
    color: #ff0000;
    font-weight: 500;
    font-size: 16px;
}

.account__form input {
    background: #fbfbfd;
    border: 1px solid #c1cbd1;
    border-radius: 3px;
}

.account__crate__title.two h3 {
    padding: 15px 0px 15px;
}

.uplode__file {
    display: flex;
    align-items: center;
    gap: 15px;
}

.account__form .account__btn .uplode__file {
    margin-top: 10px;
}

.account__form .account__btn {
    width: 150px;
    padding-top: 40px;
    margin: 0 0 0 auto;
}

.account__form.account__edit input {
    pointer-events: none;
    background: rgba(226, 226, 226, 0.9);
    border-radius: 2px;
}

.account__form.account__edit .account__btn {
    width: 225px;
    margin: 0 auto 0 0;
    padding-top: 30px;
}

.account__form.account__edit .worning__account {
    padding-top: 35px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #ff0000;
}

/* end account page */

/* debetors page */
.dashboard__body.account.debetors {
    flex-wrap: inherit;
}

.debetors__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 35px;
}

.debetors__header__left h3 {
    font-size: 24px;
    line-height: 30px;
    font-weight: 700;
}

.debetors__header__right .debtor__toggler.btn__one {
    font-size: 16px;
    width: 125px;
    height: 50px;
    border-radius: 20px;
    padding: 16px;
    background-color: #172528;
}

.debetors__header__right .btn__one i {
    padding-right: 13px;
}

.debetors__header__right .btn__one:before {
    background-color: #68bd90;
}

.debetors__header__right .btn__one:after {
    background-color: #68bd90;
}

.debetors__header__right .btn__one span {
    z-index: 11;
    position: relative;
}

.dashboard__body.account.debetors .dashboard__right {
    width: 100%;
}

.debetors__section thead th {
    padding-left: 0px;
}

.debetors__section tbody tr td {
    padding-left: 0px;
}

.debetors__section tbody tr td:first-child {
    text-align: left;
    padding-left: 14px;
}

.debetors__section .invoices__table {
    background: rgba(206, 239, 219, 0.2);
    border: 1px solid rgba(0, 0, 0, 0.05);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding: 40px;
}

.debetors__section .invoices__table input[type='text'] {
    background: transparent;
}

.debetors__section .invoices__table tbody tr:nth-child(odd) {
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.05);
}

.debetors__section .invoices__table tbody tr:nth-child(even) {
    background: transparent;
}

.debetors__section thead th:first-child {
    text-align: left;
}

.debetors__section .debtor__invoice #invoice__popup {
    text-align: justify !important;
}

.debetors__section .debtor__invoice #invoice__popup {
    text-align: justify !important;
}

.debetors__section .debtor__invoice a.invoice__toggler {
    width: 100px;
    justify-content: center;
    display: flex;
    align-items: center;
    margin: 0 0 0 auto;
    height: 30px;
    background: #caf8d4;
    border-radius: 20px;
}

.debetors__section .debtor__invoice a.invoice__toggler i {
    margin-right: 10px;
}

.debetors__section tbody tr:last-child td:last-child {
    /*padding: 14px 14px 14px 0px; */
}

.uplode__id__account a {
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    text-decoration: underline;
}

.argeement__uplode__popup {
    position: fixed;
    left: 0;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 99999;
    visibility: hidden;
    opacity: 0;
    overflow: auto;
    background: rgba(226, 226, 228, 0.8);
    -webkit-transform: translateY(101%);
    -ms-transform: translateY(101%);
    transform: translateY(101%);
    transition: all 700ms ease;
    -moz-transition: all 700ms ease;
    -webkit-transition: all 700ms ease;
    -ms-transition: all 700ms ease;
    -o-transition: all 700ms ease;
}

.argeement__uplode__popup.popup__visible {
    -webkit-transform: translateY(0%);
    -ms-transform: translateY(0%);
    transform: translateY(0%);
    visibility: visible;
    opacity: 1;
}

.argeement__uplode__popup .overlay__layer {
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    display: block;
}

.argeement__uplode__popup .close__argeement__uplode i {
    position: absolute;
    right: 25px;
    top: 25px;
    font-size: 22px;
    color: #000000;
    cursor: pointer;
    z-index: 5;
    transition: all 500ms ease;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transform: rotate(-45deg);
}

.argeement__uplode__popup .argeement__uplode__form {
    position: relative;
    padding: 40px;
    max-width: 526px;
    margin: 0 auto;
    margin-top: 150px;
    margin-bottom: 100px;
    opacity: 0;
    -webkit-transform: translateY(50px);
    -ms-transform: translateY(50px);
    transform: translateY(50px);
    z-index: 10;
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
}

.argeement__uplode__popup.popup__visible .argeement__uplode__form {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px);
    transition: all 500ms ease 500ms;
    -moz-transition: all 900ms ease 500ms;
    -webkit-transition: all 900ms ease 500ms;
    -ms-transition: all 900ms ease 500ms;
    -o-transition: all 900ms ease 500ms;
}

.argeement__uplode__crate__title h3 {
    font-size: 24px;
    line-height: 30px;
    padding-bottom: 30px;
    color: #151718;
}

.argeement__uplode__form .uplode__file__right h3 {
    color: #000000;
    font-size: 18px;
}

a.argeement__uplode__toggler {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #68bd90;
    text-decoration: underline;
}

.create__argeement__uplode {
    text-align: left;
}

.argeement__uplode__form textarea {
    width: 100%;
    background: #fbfbfd;
    border: 1px solid #c1cbd1;
    border-radius: 3px;
    height: 101px;
}

textarea:focus-visible {
    outline: 1px solid #68bd90;
}

.argeement__uplode__form .form-group label {
    padding-top: 30px;
}

.argeement__uplode__btn {
    display: flex;
    justify-content: end;
    gap: 20px;
    padding-top: 30px;
}

.argeement__uplode__btn button.btn__one {
    width: 122px;
}

.argeement__uplode__btn button.btn__one.three {
    background-color: #ff0000;
}

/* end debetors page */

/* invoice page */
.debetors__header__right .invoice__toggler.btn__one {
    font-size: 16px;
    width: 125px;
    height: 50px;
    border-radius: 20px;
    padding: 14px;
    background-color: #172528;
}

.tabelof__debetors tbody tr:nth-child(odd) td {
    color: #182123;
}

.tabelof__debetors tbody tr:nth-child(even) td {
    color: #68bd90;
}

.tabelof__debetors tbody td {
    padding-right: 10px !important;
}

.tabelof__debetors tbody th {
    padding-right: 10px !important;
}

.tableof__invoice tbody td {
    padding-right: 10px !important;
}

.tableof__invoice tbody th {
    padding-right: 10px !important;
}

.tableof__invoice.debetors tbody tr td:nth-child(2) {
    color: #ff0000;
}

.tableof__invoice.invoices .pending a {
    background: rgba(255, 0, 0, 0.1);
    border-radius: 20px;
    padding: 3px 18px;
}

.tableof__invoice.invoices tbody tr:nth-child(2) td:nth-child(3) a {
    background: rgba(75, 174, 138, 0.1);
    color: #68bd90;
}

.tableof__invoice.invoices tbody tr td:nth-child(3) a {
    color: #ff0000;
}

.tableof__invoice.invoices tbody td a {
    background: #caf8d4;
    border-radius: 20px;
    color: #000000;
    padding: 5px 18px;
}

/* end invoice page */

/* Contact page */

/* Accordion Box Style1 Css */
.accordion-box-style1 {
    position: relative;
    display: block;
}

.accordion-box-style1 .accordion {
    position: relative;
    display: block;
    margin-bottom: 20px;
    background: #ffffff;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
}

.accordion-box-style1 .accordion .accord-btn {
    position: relative;
    display: block;
    cursor: pointer;
    padding: 22px 30px;
    transition: all 500ms ease;
}

.accordion-box-style1 .accordion .accord-btn.active {
    -webkit-transition: all 0.4s linear;
    -o-transition: all 0.4s linear;
    transition: all 0.4s linear;
    box-shadow: none;
    background: transparent;
}

.accordion-box-style1 .accordion .accord-content {
    position: relative;
    padding: 10px 65px 25px 30px;
    display: none;
}

.accordion-box-style1 .accordion .accord-content.collapsed {
    display: block;
}

.accordion-box-style1 .accordion .accord-content p {
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
    margin: 0;
}

.accordion-box-style1 .accordion .accord-btn.active .plus {
    display: none;
}

.accordion-box-style1 .collapse-icon {
    position: absolute;
    right: 28px;
    top: 50%;
    transform: translate(0%, -50%);
}

.accordion-box-style1 .accordion .accord-btn .minace {
    display: none;
}

.accordion-box-style1 .accordion .accord-btn.active .minace {
    display: block;
}

.accordion-box-style1 .accordion .accord-btn h5 {
    font-weight: 500;
    font-size: 18px;
}

.accordion-box-style1 .collapse-icon span {
    font-size: 30px;
}

/* Accordion Box Style1 Css */

.contact__title h3 {
    font-size: 24px;
    padding: 30px 0px 7px;
}

.contact__title p {
    font-size: 16px;
    font-weight: 400;
}

.contact__title {
    padding-bottom: 30px;
}

.contact__us {
    display: flex;
    align-items: center;
    gap: 100px;
}

.contact__us__right .contact__number {
    color: #000000;
    padding: 30px 0px 10px;
}

.contact__us__right {
    text-align: center;
}

.contact__us__right .contact__number span {
    font-weight: 400;
}

.contact__form textarea {
    width: 100%;
    border: 1px solid #c1cbd1;
    border-radius: 3px;
    height: 137px;
}

.contact__btn {
    width: 150px;
    padding: 30px 0px 50px;
}

.faq__title h3 {
    font-size: 24px;
    color: #151718;
    padding-bottom: 28px;
}

/* end Contact page */

/*
* 15. hidden sidebar style
*/

.mobail__menu__box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    background: #ffffff;
    box-shadow: 0px 4px 20px rgb(0 0 0 / 10%);
}

.hidden-sidebar {
    position: fixed;
    top: 0;
    left: -530px;
    width: 100%;
    height: 100vh;
    z-index: 9999;
    max-width: 530px;
    overflow: auto;
    -webkit-transition: -webkit-transform 0.5s ease;
    transition: -webkit-transform 0.5s ease;
    -o-transition: transform 0.5s ease;
    transition: transform 0.5s ease;
    transition: transform 0.5s ease, -webkit-transform 0.5s ease;
    background: #ffffff;
    box-shadow: 0px 4px 20px rgb(0 0 0 / 10%);
}

.hidden-sidebar .sidebar-content .nav-logo {
    padding: 0px 30px 20px;
}

.hidden-sidebar.open {
    -webkit-transform: translate3d(530px, 0, 0);
    transform: translate3d(530px, 0, 0);
}

.hidden-sidebar .close-button {
    position: absolute;
    right: 30px;
    color: #222;
}

.hidden-sidebar.open .dashboard__menu li .active:before {
    left: 0px !important;
}

.hidden-sidebar .sidebar-content {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.side-nav {
    position: relative;
    z-index: 1000;
    cursor: pointer;
}

.hidden-sidebar .close-button {
    position: absolute;
    top: 8px;
    right: 15px;
    cursor: pointer;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
}

.hidden-sidebar.open .dashboard__menu li {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 25px 0px;
}

.hidden-sidebar.open .dashboard__menu li a span {
    font-size: 18px;
}

.tableof__invoice {
    position: relative;
    width: 100%;
    padding-bottom: 25px;
}

.uplode__file__right {
    display: flex;
    position: relative;
}

.uplode__file__right img {
    /* padding-left: 10px; */
}

.invoice__form .tabs ul.tabs-nav.two {
    justify-content: space-around;
}

.invoice__form .tabs ul.tabs-nav.two li.active {
    justify-content: space-around;
}

.dashboard__header .header__right .btn-link {
    color: #151718;
    text-decoration: none;
    font-family: 'Mulish', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    transition: all 500ms ease;
}

.btn-link:hover {
    color: #151718;
}

.btn-link:focus {
    box-shadow: none;
}

.Pending {
    position: absolute;
    right: 50px;
    top: 30px;
    border: 1px solid #ff0000;
    border-radius: 20px;
    padding: 15px 30px;
    color: #ff0000;
    font-weight: 500;
    font-size: 16px;
}

.Disapproved {
    position: absolute;
    right: 50px;
    top: 30px;
    border: 1px solid #ff0000;
    border-radius: 20px;
    padding: 15px 30px;
    color: #ff0000;
    font-weight: 500;
    font-size: 16px;
}

.Approved {
    position: absolute;
    right: 50px;
    top: 30px;
    border: 1px solid #68bd90;
    border-radius: 20px;
    padding: 15px 30px;
    color: #68bd90;
    font-weight: 500;
    font-size: 16px;
}

.file-upload-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 34px;
    border: 1px solid #c1cbd1;
    border-radius: 10px;
}

.file-upload-label h3 {
    font-size: 16px;
}

.uploaded-file {
    font-size: 14px;
    margin-left: 4px;
}

.account__blance {
    display: flex;
    align-items: center;
    gap: 10px;
}

.account__blance .update__blance button {
    background: #172528;
    border-radius: 20px;
    color: #ffffff;
    padding: 10px 10px;
}

.btn-link {
    text-decoration: none;
    font-family: 'Mulish', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #68bd90;
    transition: all 500ms ease;
}

.sign-canvas {
    width: 100%;
    height: 160px
}

.form-check-input:checked {
    background-color: #68bd90;
    border-color: #68bd90;
}

.form-check-input:focus {
    border-color: #68bd90;
    outline: 0;
    box-shadow: none;
}

/* .table > :not(:last-child) > :last-child > * {
    text-align: center !important;
} */

textarea {
    height: 151px;
    background: #fbfbfd;
    border: 1px solid #c1cbd1;
    border-radius: 3px;
    padding: 10px 20px;
}

.view__popup .debtor__btn {
    display: flex;
    gap: 20px;
    justify-content: end;
    margin: 0 0 0 auto;
}

.uplode__file__right :hover {
    cursor: pointer !important;
}

.uplode__file :hover {
    cursor: pointer !important;
}

.debtorFileUpload :hover {
    cursor: pointer !important;
}

.COCFileUpload :hover {
    cursor: pointer !important;
}

.file-upload-label :hover {
    cursor: pointer !important;
}

/* .signAgreement:hover {
    cursor:url(/public/images/pencil2.png), auto;
} */

body::-webkit-scrollbar {
    width: 10px !important;
}

body::-webkit-scrollbar-track {
    background: rgba(170, 182, 175, 0.2) !important;
}

body::-webkit-scrollbar-thumb {
    background-color: rgba(12, 104, 49, 0.2) !important;
    border-radius: 20px !important;
    border: 3px solid rgba(96, 225, 148, 0.2) !important;
}

div::-webkit-scrollbar {
    width: 10px !important;
}

div::-webkit-scrollbar-track {
    background: rgba(170, 182, 175, 0.2) !important;
}

div::-webkit-scrollbar-thumb {
    background-color: rgba(12, 104, 49, 0.2) !important;
    border-radius: 20px !important;
    border: 3px solid rgba(96, 225, 148, 0.2) !important;
}

form::-webkit-scrollbar {
    width: 10px !important;
}

form::-webkit-scrollbar-track {
    background: rgba(170, 182, 175, 0.2) !important;
}

form::-webkit-scrollbar-thumb {
    background-color: rgba(12, 104, 49, 0.2) !important;
    border-radius: 20px !important;
    border: 3px solid rgba(96, 225, 148, 0.2) !important;
}

div {
    scrollbar-color: rgba(12, 104, 49, 0.2) rgba(170, 182, 175, 0.2);
    scrollbar-width: thin;
}

:root {
    scrollbar-color: rgba(12, 104, 49, 0.2) rgba(170, 182, 175, 0.2);
    scrollbar-width: thin;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.react-datepicker-popper {
    z-index: 1000;
}

.react-datepicker__month-select,
.react-datepicker__year-select {
    border: none !important;
    margin-top: 10px !important;
}

.contract-download-link {
    color: #151718;
}

.contract-download-link:hover {
    color: #68bd90
}

.toolTipPlacement {
    margin-top: 26px !important;
}

.togglePlacement {
    padding-top: 12px;
}