.invoice__popup {
    position: fixed;
    left: 0;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 1000;
    visibility: hidden;
    opacity: 0;
    overflow: auto;
    background: rgba(226, 226, 228, 0.8);
    -webkit-transform: translateY(101%);
    -ms-transform: translateY(101%);
    transform: translateY(101%);
    transition: all 700ms ease;
    -moz-transition: all 700ms ease;
    -webkit-transition: all 700ms ease;
    -ms-transition: all 700ms ease;
    -o-transition: all 700ms ease;
}
.invoice__popup .overlay__layer {
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    display: block;
}
.invoice__popup.popup__visible {
    -webkit-transform: translateY(0%);
    -ms-transform: translateY(0%);
    transform: translateY(0%);
    visibility: visible;
    opacity: 1;
}

.invoice__popup.popup__visible .fq-modal-body {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px);
    transition: all 500ms ease 500ms;
    -moz-transition: all 900ms ease 500ms;
    -webkit-transition: all 900ms ease 500ms;
    -ms-transition: all 900ms ease 500ms;
    -o-transition: all 900ms ease 500ms;
}

.invoice__popup .fq-modal-body {
    position: relative;
    padding: 30px 40px 30px;
    margin: 0 auto;
    margin-top: 30px;
    /*margin-bottom: 100px;*/
    opacity: 0;
    -webkit-transform: translateY(50px);
    -ms-transform: translateY(50px);
    transform: translateY(50px);
    z-index: 10;
    background: #ffffff;
    box-shadow: 0px 4px 20px rgb(0 0 0 / 5%);
    opacity: 1;
}

.invoice__popup.popup__visible .invoice__form {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px);
    transition: all 500ms ease 500ms;
    -moz-transition: all 900ms ease 500ms;
    -webkit-transition: all 900ms ease 500ms;
    -ms-transition: all 900ms ease 500ms;
    -o-transition: all 900ms ease 500ms;
}
